/* eslint-disable max-lines */
const en = {
  translations: {
    datepicker: {
      placeholderText: 'dd.mm.yyyy'
    },
    table: {
      loading: 'Loading...'
    },
    select: {
      placeholderText: 'Please, choose...',
      smokeProducts: {
        cigarettes: 'Cigarettes',
        cigar: 'Cigar',
        pipe: 'Pipe',
        chewingTobacco: 'Chewing tobacco',
        snuff: 'Snuff',
        eCigs: 'E-cigs',
        other: 'Other',
      },
      heightUnits: {
        cm: 'cm',
        inch: 'inch'
      },
      weightUnits: {
        kg: 'kg',
        pound: 'pound'
      },
      parents: {
        one: 'one',
        both: 'both'
      }
    },
    radio: {
      gender: {
        male: 'Male',
        female: 'Female',
      },
      yesNo: {
        no: 'No',
        yes: 'Yes',
        na: 'NA',
        dontKnow: 'Don\'t know'
      },
    },
    marks: {
      more: 'or more',
      less: 'or less',
      hour: 'h',
      week: 'w',
      day: 'd',
      moreHours: '$t(marks.hour) $t(marks.more)',
      terrible: 'Terrible',
      perfect: 'Perfect',
      seldom: 'Seldom',
      perWeek: 'Per Week',
      never: 'Never',
      often: 'Often',
    },
    header: {
      instructions: 'Instructions',
      help: 'Help',
      logo: 'Logo'
    },
    pageHeader: {
      basicData: 'Basic Data',
      smoking: 'Smoking',
      alcohol: 'Alcohol',
      coffeAndTea: 'Coffee and Tea',
      exercises: 'Exercising',
      existingConditions: 'Existing Conditions',
      medications: 'Medications',
      family: 'Family',
      psychometric: 'General Wellbeing',
      nutrition: 'Nutrition',
      search: 'Search',
      lifeStyle: 'Lifestyle',
      morbidity: 'Morbidity',
      metabolicMetrics: 'Measurements an Lab Results',
      riskFactors: 'Risk Factors',
      riskFactorSummary: 'Risk Factor Summary',
      precursorRisks: 'Morbidity Risks',
      mortalityRisks: 'Mortality Risks',
      СHD: 'СHF Survival and Intervention Choices',
      plusRisks: 'Risks',
      diseaseRisks: 'Disease Risks',
      sleep: 'Sleep'
    },
    basicData: {
      name: 'Name',
      familyName: 'Family name',
      gender: 'Gender',
      birthDate: 'Date of birth',
      height: 'Height',
      weight: 'Weight',
      bmi: 'BMI (kg/m2)',
      birthYear: 'Year of birth',
      birthMonth: 'Month of birth',
      addInfo: 'Additional information'
    },
    pageOneQuestions: {
      smoking: {
        smkNow: 'Are you currently a smoker?',
        smkEver: 'Were you ever a smoker?',
        smkStartYear: 'When did start smoking?',
        smkQuitYear: 'When did you quit?',
        smoke: 'What product {{time}} you use?',
        smkNum: 'About how many {{name}} {{time}} you smoke per day?',
        smokePresentTime: 'What product do you use?',
        smokePastTime: 'What product did you use?',
        smkNumPresentTime: 'About how many {{name}} do you smoke per day?',
        smkNumPastTime: 'About how many {{name}} did you smoke per day?'
      },
      alcohol: 'On average, how many alcohol drinks do you take per week?',
      coffeAndTea: 'On average, about how many cups of coffee and/or tea do you drink per day?',
      exercises: 'On average, about how many hours per week do you exercise?',
      diagnosedHypertension: 'Has a doctor ever told you you have high blood pressure (hypertension)?',
      diagnosedDiabet: 'Has a doctor ever told you you have high blood sugar (diabetes)?',
      highBloodPressure: 'Are you under treatment for high blood pressure?',
      highBloodSugar: 'Are you under treatment for high blood sugar?',
      family: 'Did either of your parents develop cardiovascular disease before the age of 60?',
      sleepWeekdays: 'How many hours of sleep do you get on weekdays?',
      sleepWeekends: 'How many hours of sleep do you get on weekends or holidays?',
      isRefreshed: 'Do you wake up refreshed?',
      psychometric: 'How healthy do you feel?',
      fruitsVegetables: 'About how often do you eat fruit and vegetables per week?',
      fishMeat: 'About how often do you eat red meat, poultry, or fish per week?'
    },
    phaseOneResult: {
      condition: {
        good: 'good',
        veryGood: 'very good',
        excellent: 'excellent',
      },
      graph: {
        health: 'Your health',
        estimate: 'estimate'
      },
      result: {
        success: 'Congratulations! Your health looks {{health}}!<br /> Adopt your healthy habits.',
        failure: `You might have some health issues to look into.
          We suggest you consult your doctor or visit one
          of our affiliated laboratories for a deeper evaluation,
          we would like to take a blood test and complete your medical history.
          To set up an appointment click the link below.`,
        id: 'Your Id'
      },
      report: 'Get report'
    },
    search: {
      error: 'Patient not found',
      find: 'Search'
    },
    lifeStyle: {
      smokeTime: 'Smoking',
      packYears: 'Pack-years',
      since: 'since',
      to: 'to',
      aDay: 'a day',
      alcoBeerPerWeek: {
        label: 'Beer',
        popOverText: 'On average, how many beer do you drink per week?',
      },
      alcoWinePerWeek: {
        label: 'Wine',
        popOverText: 'On average, how many wine do you drink per week?',
      },
      alcoWhiskeyPerWeek: {
        label: 'Liquors',
        popOverText: 'On average, how many alcohol drinks do you take per week?',
      },
      coffePerDay: {
        label: 'Coffee',
        popOverText: 'On average, about how many cups of coffee do you drink per day?',
      },
      teaPerDay: {
        label: 'Tea',
        popOverText: 'On average, about how many cups of tea do you drink per day?',
      },
      exerciseWalkPerWeek: {
        label: 'Walk',
        popOverText: 'On average, about how many hours per week do you walk?',
      },
      exerciseRunPerWeek: {
        label: 'Run',
        popOverText: 'On average, about how many hours per week do you run?',
      },
      exerciseBicyclePerWeek: {
        label: 'Bicycle',
        popOverText: 'On average, about how many hours per week do you bike?',
      },
      exerciseSimulatorsPerWeek: {
        label: 'Simulators',
        popOverText: 'On average, about how many hours per week do you use the simulators?',
      },
      exerciseSwimmingPerWeek: {
        label: 'Swimming',
        popOverText: 'On average, about how many hours per week do you swimming?',
      },
      nutFruit: {
        label: 'Fruit',
        popOverText: 'About how often do you eat two or more pieces of fruit in a day?',
      },
      nutVegetables: {
        label: 'Vegetables',
        popOverText: 'About how often do you eat one or more portions of vegetables in a day?',
      },
      nutChicken: {
        label: 'Poultry',
        popOverText: 'About how often do you eat 150 grams or more of poultry per week?',
      },
      nutRedMeat: {
        label: 'Red meat',
        popOverText: 'About how often do you eat 150 grams or more of red meat per week?',
      },
      nutFish: {
        label: 'Fish',
        popOverText: 'About how often do you eat 150 grams or more of fish per week?',
      },
    },
    morbidity: {
      treated: 'Treated',
      diagnosedDiabet: 'Diabetes',
      diagnosedHypertension: 'Hypertension',
      highBloodSugar: '$t(morbidity.diagnosedDiabet) $t(morbidity.treated)',
      highBloodPressure: '$t(morbidity.diagnosedHypertension) $t(morbidity.treated)',
      kidneyDesease: 'Kidney disease',
      metabolicSyndrome: 'Metabolic dysfunction',
      cardiovascular: 'Cardiovascular',
      stroke: 'Stroke',
      cancer: 'Cancer',
    },
    family: {
      parents: 'Parents'
    },
    metrics: {
      mmHg: 'mmHg',
      mmolL: 'mmol/L',
      UL: 'U/L',
      bloodPressureSystolic: 'Systolic blood pressure ($t(metrics.mmHg))',
      bloodPressureDiastolic: 'Diastolic blood pressure ($t(metrics.mmHg))',
      lipidTotalCholesterol: 'Total cholesterol ($t(metrics.mmolL))',
      highDensityLiprotein: 'High-density lipoprotein HDL ($t(metrics.mmolL))',
      lowDensityLiprotein: 'Low-density lipoprotein LDL ($t(metrics.mmolL))',
      ratioOfTotalCholesterolToHdl: 'Ratio of total cholesterol to HDL',
      triglycerides: 'Triglycerides ($t(metrics.mmolL))',
      fastingBloodGlucose: 'Fasting blood glucose ($t(metrics.mmolL))',
      glycatedHemoglobin: 'Glycated haemoglobin (%)',
      cReactiveProtein: 'C-reactive protein (mg/L)',
      alanineAminotransferase: 'Alanine aminotransferase ($t(metrics.UL))',
      aspartateAminotransferase: 'Aspartate aminotransferase ($t(metrics.UL))',
      gammaGlutamylTransferase: 'Gamma-glutamyl transferase ($t(metrics.UL))',
      glomerularFiltrationRate: 'Glomerular filtration rate (mL/min/1.73 m^2)',
      albuminCreatinineUrineRatio: 'Albumin-creatinine urine ratio (mg/mmol)',
    },
    riskFactors: {
      normalRange: 'Population statistics range',
      normalValue: 'Expected value',
      expectedRange: 'Expected range',
      actual: 'Actual',
      obesity: 'Obesity',
      movement: 'Movement',
      sleep: 'Sleep',
      glucose: 'Glucose',
      lipids: 'Lipids  ',
      bloodPressure: 'Blood pressure',
      ironMetabolism: 'Iron metabolism',
    },
    risks: {
      timeHorizon: 'Time Horizon {{time}} Years',
      riskRatio: 'Absolute risks',
      cardiovascular: 'Cardiovascular disease',
      metabolicDysfunction: 'Metabolic dysfunction',
      liver: 'Liver disease',
      dementia: 'Dementia',
      atherosclerosis: 'Atherosclerosis',
      COPD: 'Chronic obstructive pulmonary disease',
      OSA: 'Obstructive sleep apnea',
      ACM: 'All-cause mortality',
      CHF: 'Congestive heart failure',
      aMI: 'Acute myocardial infarction',
      ESLD: 'End-stage liver disease',
      ESRD: 'End-stage renal disease',
      diabetesMellitus2: 'Diabetes Mellitus 2',
      heartFailure: 'Heart failure',
      totalCancer: 'Total cancer'
    },
    export: {
      printExport: 'Print/Export',
      report: 'report',
      patient: '$t(export.printExport) patient $t(export.report)',
      physician: '$t(export.printExport) physician $t(export.report)',
    },
    drillDownGraph: {
      chart: {
        years: 'Years',
        survival: 'Survival probability',
        intervention: 'intervention',
        noIntervention: 'no $t(drillDownGraph.chart.intervention)',
      },
      filters: {
        data: 'Data',
        intervention: 'Intervention',
        age: 'Age',
        gender: 'Sex',
        weight: '$t(basicData.weight) (kg)',
        nyhaClass: 'NYHA Class',
        ejectionFraction: 'Ejection Fraction (%)',
        ischemicEtiology: 'Ischemic Etiology',
        sbp: 'SBP (mmHG)',
        sodium: 'Sodium (mEq/L)',
        totalCholesterol: 'Total Cholesterol (mmol/L)',
        hemoglobin: 'Hemoglobin (g/dL)',
        lymphocytes: 'Lymphocytes (%)',
        uricACid: 'Uric Acid (mg/dL)',
        diureticDose: 'Diuretic dose (mg/day)',
        aceInhibitor: 'Ace inhibitor',
        bBlocker: 'β blocker',
        arb: 'ARB',
        ksparingDiuretic: 'K-sparing diuretic',
        allopurinolUse: 'Allopurinol use',
        statinUse: 'Statin use',
        biventricularPacemaker: 'Biventricular pacemaker',
        icd: 'ICD',
        biventricularIcd: 'Biventricular ICD',
      }
    },
    plusRisks: {
      chart: {
        timeHorizon: 'Time horizon: 5 years',
        averageValue: 'Average value',
        baseLineValue: 'Baseline value',
        confidenceInterval: 'Confidence interval',
        reset: 'Reset'
      },
      filter: {
        quitSmoking: 'Quit smoking'
      }
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    report: {
      underweight: 'underweight',
      normal: 'normal',
      overweight: 'excessive weight',
      obese: 'obese',
      harmless: 'harmless',
      harmlessCigarette: 'harmless',
      slightlyHarmful: 'slightly harmful',
      extremelyHarmful: 'extremely harmful',
      requiresAction: 'requires action from your side',
      insufficient: 'insufficient',
      sufficient: 'sufficient',
      good: 'good',
      healthy: 'healthy',
      notQuiteHealthy: 'not quite healthy',
      unhealthy: 'unhealthy',
      stronglyIncreased: 'strongly increased',
      slightlyIncreased: 'slightly increased',
      low: 'low',
      increased: 'increased',
      lipidStronglyIncreased: 'strongly increased',
      lipidSlightlyIncreased: 'slightly increased',
      lipidLow: 'low',
    },
    overallRisk: {
      good: 'good',
      fair: 'fair',
      poor: 'poor'
    },
    validator: {
      presence: 'value is required',
      tooLong: 'is too long (maximum is {count, number} {count, plural, one {character} other {characters}})',
      wrongLength: 'is the wrong length (should be {count, number} {count, plural, one {character} other {characters}})',
      invalid: 'is invalid',
      notANumber: 'is not a number',
      notAnInteger: 'is not an integer',
      greaterThan: 'must be greater than {count, number}',
      lessThanOrEqualTo: 'must be less than {count, number}',
      greaterThanOrEqualTo: 'must be greater than or equal to {count, number}',
      lessThan: 'must be less than {count, number}'
    },
    combinedPhaseResult: {
      id: 'Your Id',
      info: 'Write it down if you want to share information with your healthcare provider.',
      report: 'Get report'
    },
    phase1Report: {
      pdfBtn: 'PDF',
      header: 'Phase 1 doc',
      needMedicineYes1: 'You may have some health issues that should be investigated. The below tips may help you to make your life healthier.  However we strongly recommend you to contact your primary care doctor in this regard or visit one of our labs for a more detailed analysis. We will do some blood tests for a complete picture of your health condition.',
      needMedicineYes2: 'To make an appointment, please use this link: [LINK TO REFERENCE SITE]',
      needMedicineNo1: 'Congratulations! Your health is not exposed to any significant risks. Make sure you maintain a healthy lifestyle and consider doing a health check again in a year. The below tips may help you to make your life healthier. Even with your good results, a deeper check could be worthwhile and we would like to invite you to contact one of our labs for a blood test.',
      needMedicineNo2: 'To make an appointment, please use this link: [LINK TO REFERENCE SITE]',
      generalRecommendationTitle: 'General health tips',
      generalRecommendationSubTitle: 'Here are our four evidence-Based practical keys to healthfulness.',
      point1Title: 'Don\'t smoke',
      point1Body: 'Smoking is by far the most important significant risk factor but if you quit or not is all about your own willpower. A number of studies examining a life expectancy among smokers of different age and gender groups reveals that smokers lose between 5 and 11 years of lifespan. Smoking is also the main risk factor known to affect the development of chronic obstructive pulmonary disease (COPD) and lung cancer. A newest study found that smoking even one cigarette a day carries significant health consequences, namely a higher risk of heart attack and stroke. This means resolve to give up smoking! There are many different societies and programs available to encourage people like you to stop smoking.',
      point2Title: 'Regular exercise',
      point2Body: 'It\'s easy to put exercise and activity to the bottom of your to-do list, but being active to maintain endurance is one of the most important things you can do. At least 75 min. of high-intensity or 150 min of moderate-intensity physical activity or a combination of both divided into 4-6 training sessions weekly help to significantly reduce the cardiovascular risks. We recommend you to do a strength training 2 days a week to target all muscle groups, but especially the core muscles. We therefore recommend you a six-day workout plan consisting of the following components: strength, endurance, coordination / balance and flexibility.',
      point3Title: 'Healthy eating',
      point3Body: 'There are literally tons of recommendations on how to eat healthily. We strongly recommend you to pay your attention to a Mediterranean diet, which has been well documented and the best scientifically proven. The most important ingredients of the Mediterranean diet are olive oil, plenty of fresh vegetables, fruits and fish. A glass of (red) wine is also allowed. If possible, eat not alone, but with family or friends. Communal eating has health benefits and allow you to really enjoy the break away from your everyday life. Avoid sugar, ready-made products, and highly processed foods such as white flour. Drink more water, especially when you are sweating.',
      point4Title: 'Get enough sleep',
      point4Body: 'In recent years, the importance of sleep for health in general and especially for normal human cognitive performance has come to the fore. During the REM- sleep (also called dream phase), all memory content is being consolidated and a deep sleep flushes toxic waste out and thus cleans the brain from daily waste. The most important points of good sleep hygiene are: a dark, quiet and cool bedroom, avoid caffeine after 4 p.m. and any stimulating activities after 9 p.m., especially no e-mails, text messages, etc. in bed. From ca. 9 p.m. make sure to switch all screens to night mode. Also don’t go to bed on a full stomach and avoid working or even watching TV in your bedroom. And last but not least, the bed should be comfortable and the pillow should allow the head and neck to be relaxed and well supported.',
    },
    phase2Report: {
      header: 'Your results',
      headerText1: 'Thank you for using our digital check-up service to learn more about your health risks. The following analyses and statements are based on your health-related data and measurements as well as on the laboratory results. The analysis is based on the collective knowledge of over 5,000 medical professionals and the results of clinical studies comprising over 400 million person-years worth data.',
      headerText2: 'We will provide you with your results, interpret them and give you valuable tips on how you can improve your health situation.',
      riskFactors: 'Risk factors',
      overweightTitle: 'Overweight and obesity',
      overweightText1: 'Overweight and obesity belong to the main risk factors for a number of diseases, such as cardiovascular diseases, liver dysfunction and diabetes. In addition, increased body weight can lead to increased joint problems and put you at risk of developing back pain. Being underweight can cause health problems, just as being overweight, because reserves are important in case of serious illnesses.',
      overweightText2: 'With a body mass index of {{bmi}}, your weight is in relation to your height is:',
      alcoholTitle: 'Harmful use of alcohol',
      alcoholText1: 'Over the years, scientists have documented that above a certain amount and / or frequency, alcohol consumption may result in lasting health problems even in case of healthy people. Alcohol is one of several substances that can damage your liver and excessive alcohol consumption disturbs sleep and thus has a negative impact on your mental health. In addition, the risk of accidents is significantly increased under the influence of alcohol',
      alcoholText2: 'According to your information your alcohol consumption is:',
      smokingTitle: 'Cigarette smoking',
      smokingText1: 'Smoking or especially inhaling cigarette smoke is the most important risk factor but it’s all about your own willpower if you quit or not.  Life expectancy for smokers is at least 10 years shorter than for nonsmokers because of lung diseases, cardiovascular diseases and especially various types of cancer. Smoking even one cigarette a day carries significant health consequences. This means resolve to give up smoking!',
      smokingText2: 'Your behavior is:',
      movementTitle: 'Sedentary lifestyle',
      movementText1: 'Modern technology has changed our lives and made it more comfortable and convenient engaging people in behaviors that expend very little energy. Time spent being sedentary is an emerging risk factor for poor health. Physical activity primarily prevents, or delays the risk of cardiovascular diseases, cancer and a decline in mental abilities. We recommend you to include either 75 min. of high-intensity or 150 min of moderate-intensity physical activity or a combination of both divided into 4-6 training sessions a week into your to-do list. Doing a  strength training 2 days a week to target all muscle groups would be an ideal supplementation to your healthy lifestyle.',
      movementText2: 'According to your information your movement profile is:',
      nutritionTitle: 'Unhealthy eating habits',
      nutritionText1: 'Modern eating habits often result in eating energy-dense and non-nutrient-rich foods, rich in salt, sugar, fats and preservatives. Healthy eating includes 2 servings of fruits and 2 servings of vegetables per day, whole grains and protein foods. Consider reducing or avoiding sugar, highly processed finished products, and especially processed meat products.',
      nutritionText2: 'According to your information your eating habits are:',
      sleepTitle: 'Lack of sleep',
      sleepText1: 'Our understanding of the purpose for sleep has enormously increased in recent years. Sleep gives your body not only time to rest and recharge, but also it is crucial to your brain\'s ability to learn and remember, it drives toxic metabolic byproducts out of your brain and cleans it while you sleep. The amount of sleep a person needs depends on many things, but healthy adults need on average between 7 and 8 ½ hours of sleep per night. If you\'re a morning lark or a night owl is a matter of preference and your chronotype. The best way to perfect your sleep habits is to use your time off naturally while on vacation, where you can go to bed and get up freely without any alarm clock.',
      sleepText2: 'According to your information your sleep habits are:',
      bloodPressureTitle: 'High blood pressure',
      bloodPressureText1: 'High blood pressure is just one of the most common diseases and, just as in case of diabetes, there is a genetic predisposition to it and it also develops gradually. High blood pressure is a risk factor for cardiovascular disease, especially stroke. Lifestyle changes can often be enough to normalize blood pressure.',
      bloodPressureText2: 'According to our calculations and measurements, your risk is:',
      glucoseTitle: 'Sugar metabolism',
      glucoseText1: 'Regulation of glucose in the body is one of the most important metabolic tasks. A delicate balance between hormones and enzymes ensures a balanced sugar level largely independent of meals. People with type 2 diabetes does not have a clear pattern of inheritance, although many affected individuals have at least one close family member, such as a parent or sibling, with the disease. The risk of developing type 2 diabetes increases with the number of affected family members. Over time, high blood sugar levels can decreases the elasticity of blood vessels and damage vital organs.',
      generalText2: 'According to our calculations and measurements, your risk is:',
      lipidsTitle: 'Lipid metabolism disorder',
      lipidsText1: 'Similar as in the case of sugar metabolism disorder, your genetic profile essentially determines the amount of cholesterol and triglycerides in your blood. Both fats are essential building blocks for the body, but if their values are too high they are harmful, especially for the blood vessels and can lead to heart disease, strokes or other circulatory disorders.',
      ironMetabolismTitle: 'Iron metabolism',
      ironMetabolismText1: 'Iron is an essential element for blood production, and about 70 percent of your body\'s iron is in the red blood cells called hemoglobin.  Since iron absorption occurring in the upper part of the intestine is limited, in the case of impaired absorption or decreased intake caused by a number of reasons, from related medical conditions to blood loss to diet choices, iron deficiency, or sideropenia can occur. Early detection of iron deficiency is extremely important.',
      diseaseRisks: 'Disease risks',
      diabetesMellitus2Title: 'Diabetes',
      diabetesMellitus2Text1: 'Type 2 diabetes is one of the most common diseases often caused by genetic factors. The disease develops insidiously and is usually linked to weight gain. Diabetics have a significantly increased risk of cardiovascular disease. Regular sports activities and a low carbohydrate diet aimed at weight reduction are the key elements in the prevention and management of type 2 diabetes.',
      diagnosedHypertensionTitle: 'Hypertension',
      diagnosedHypertensionText1: 'High blood pressure or hypertension is just one of the most common diseases and, just as in case of diabetes, there is a genetic predisposition to it. High blood pressure develops gradually over many years combined with weight gain. High blood pressure is a risk factor for cardiovascular disease, especially stroke as well as kidney dysfunction. Lifestyle changes toward more physical activities and healthier eating can often be enough to normalize blood pressure.',
      kidneyDeseaseTitle: 'Kidney dysfunction',
      kidneyDeseaseText1: 'The kidneys are essential for balancing the body\'s water and salt balance as well as removal of waste products and excess fluid from the body. Since there are usually no symptoms of kidney disease in the early stages an occasional check-up is crucial to be able to detect and treat the disease as soon as possible.',
      liverTitle: 'Liver dysfunction',
      liverText1: 'The liver is our central metabolic organ and is often referred to as “the body\'s chemical factory”. Liver problems can be caused by a variety of factors that damage the liver, such as viruses, toxic substances, excessive alcohol consumption, etc. Over time, conditions that damage the liver can lead to scarring, which can lead to liver failure, a life-threatening condition. But early treatment may give the liver time to heal.',
      metabolicDysfunctionTitle: 'Disorder of lipid metabolism',
      metabolicDysfunctionText1: 'Elevated triglyceride and cholesterol levels can be inherited (genetic), or may be caused by other medical conditions. Lipid metabolism disorders are characterized by\'hardening of the arteries\', which happens when fat, cholesterol, and other substances build up in the walls of arteries. These deposits are called plaques. Over time, these plaques can narrow or completely block the arteries and cause problems throughout the body. Accurate and early detection of lipid metabolism disorder is essential for timely decisions on therapy to slow or even partially reverse it.',
      heartFailureTitle: 'Heart failure',
      heartFailureText1: 'A significant number of factors and genetic variants can trigger a weakening of the heart muscles, which compromises heart\'s pumping ability and thus causes a considerable loss of quality of life. Certain risk factors for heart failure can be controlled and even timely prevented.',
      overallRisk: 'Overall risk',
      healthSliceTitle: 'General health status',
      healthSliceText: 'In general your overall health status can be regarded as mostly',
      healthSliceHigh: 'We congratulate you on your result! Be sure to maintain your good habits for healthy life. We recommend you to consider doing a health check again in a year.',
      healthSliceMid: 'Despite some positive results we have detected several negative health outcomes. Knowing the risks you face can help you find ways to avoid health problems. We recommend you to consult with your doctor or physician about the outcomes of your check-up.',
      healthSliceLow: 'We have found out that you are at increased risk of health problems. We strongly recommend you to consider making an appointment your doctor to discuss the outcomes of your check-up. Together with your doctor or medical advisor of your trust you can work out your plan of return into a healthy lifestyle.',
    }
  }
};

export default en;
