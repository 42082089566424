/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-lines */
const ru = {
  translations: {
    datepicker: {
      placeholderText: 'дд.мм.гггг'
    },
    table: {
      loading: 'Загрузка...'
    },
    select: {
      placeholderText: 'Пожалуйста, выберите',
      smokeProducts: {
        cigarettes: 'Сигареты',
        cigar: 'Сигары',
        pipe: 'Трубка',
        chewingTobacco: 'Жевательный табак',
        snuff: 'Нюхательный табак',
        eCigs: 'Электронные сигареты',
        other: 'Другое',
      },
      heightUnits: {
        cm: 'см',
        inch: 'дюйм'
      },
      weightUnits: {
        kg: 'кг',
        pound: 'фунтов'
      },
      parents: {
        one: 'один',
        both: 'оба'
      }
    },
    radio: {
      gender: {
        male: 'Муж.',
        female: 'Жен.',
      },
      yesNo: {
        no: 'Нет',
        yes: 'Да',
        na: 'Не применимо',
        dontKnow: 'Не знаю'
      }
    },
    marks: {
      more: 'или более',
      less: 'или менее',
      hour: 'ч',
      week: 'нед',
      day: 'д',
      moreHours: '$t(marks.hour) $t(marks.more)',
      terrible: 'Ужасно',
      perfect: 'Отлично',
      seldom: 'Редко',
      perWeek: 'в неделю',
      never: 'Никогда',
      often: 'Часто',
    },
    header: {
      instructions: 'Инструкции',
      help: 'Помощь',
      logo: 'Логотип'
    },
    pageHeader: {
      basicData: 'Основная информация',
      smoking: 'Курение',
      alcohol: 'Алкоголь',
      coffeAndTea: 'Кофе и чай',
      exercises: 'Физическая активность',
      existingConditions: 'Текущее состояние',
      medications: 'Принимаемые лекарства',
      family: 'Фамильный анамнез',
      psychometric: 'Психометрия',
      nutrition: 'Питание',
      search: 'Поиск',
      lifeStyle: 'Образ жизни',
      morbidity: 'Тяжесть заболевания',
      metabolicMetrics: 'Показатели метаболизма',
      riskFactors: 'Факторы',
      riskFactorSummary: 'Факторы риска',
      precursorRisks: 'Риски заболеваемости',
      mortalityRisks: 'Риск смертности',
      СHD: 'ВПС и хирургические вмешательства',
      plusRisks: 'Риски',
      sleep: 'Сон'
    },
    basicData: {
      name: 'Имя',
      familyName: 'Фамилия',
      gender: 'Пол',
      birthDate: 'Дата рождения',
      height: 'Рост',
      weight: 'Вес',
      bmi: 'ИМТ (кг/м2)',
      birthYear: 'Год рождения',
      birthMonth: 'Месяц рождения',
      addInfo: 'Дополнительная информация'
    },
    pageOneQuestions: {
      smoking: {
        smkNow: 'Курите ли вы в настоящее время?',
        smkEver: 'Курили ли вы ранее?',
        smkStartYear: 'В каком году вы начали курить?',
        smkQuitYear: 'В каком году вы бросили  курить?',
        smoke: 'Какие сигареты {{time}} вы курили?', // Варианты {{time}} эmо или presentTime или pastTime
        smkNum: 'Примерно сколько сигарет {{name}} {{time}} вы курите в день?', // Варианты {{name}} берутся из select.smokeProducts
        presentTime: 'сейчас',
        pastTime: 'раньше',
        smokePresentTime: 'Что вы курите?',
        smokePastTime: 'Что вы курили?',
        smkNumPresentTime: 'Примерно сколько {{name}} вы курите в день?',
        smkNumPastTime: 'Примерно сколько {{name}} вы курили в день?'
      },
      alcohol: 'В среднем, сколько алкогольных напитков вы выпиваете в неделю?',
      coffeAndTea: 'В среднем, сколько чашек кофе и/или чая вы выпиваете в день?',
      exercises: 'В среднем, сколько часов в неделю вы уделяете спортивным тренировкам?',
      diagnosedHypertension: 'Говорил ли вам когда-нибудь врач, что у вас повышенное давление (гипертензия)?',
      diagnosedDiabet: 'Говорил ли вам когда-нибудь врач, что у вас повышен сахар в крови (диабет)?',
      highBloodPressure: 'Проходите ли вы в данный момент лечение от повышенного давления?',
      highBloodSugar: 'Проходите ли вы в данный момент лечение от повышенного сахара в крови?',
      family: 'Страдали ли ваши родители от сердечно-сосудистых заболеваний до достижения возраста 60 лет?',
      sleepWeekdays: 'Сколько часов вы спите в будние дни?',
      sleepWeekends: 'Сколько часов вы спите в выходные или праздники?',
      isRefreshed: 'Вы просыпаетесь отдохнувшим?',
      psychometric: 'Насколько здоровым/здоровой вы себя ощущаете?',
      fruitsVegetables: 'Сколько раз в неделю вы в среднем едите фрукты или овощи?',
      fishMeat: 'Сколько раз в неделю вы в среднем едите красное мясо, птицу или рыбу?'
    },
    phaseOneResult: {
      condition: {
        good: 'хорошо',
        veryGood: 'очень хорошо',
        excellent: 'отлично',
      },
      graph: {
        health: 'Оценка Вашего',
        estimate: 'здоровья'
      },
      result: {
        success: 'Поздравляем! Ваше здоровье оценивается как {{health}}!<br /> Поддерживайте здоровый образ жизни.', // Варианты  {{health}} берутся из phaseOneResult.condition
        failure: `Возможно, вам стоит заняться своим здоровьем.
          Рекомендуем обратиться к врачу или посетить одну
          из наших аффилированных лабораторий для более детального осмотра,
          чтобы мы могли взять у вас анализ крови для составления полного анамнеза.
          Записаться на прием можно по ссылке ниже.`,
        id: 'Ваш Id'
      },
      report: 'Получить отчет'
    },
    search: {
      error: 'Пациент не найден',
      find: 'Найти'
    },
    lifeStyle: {
      smokeTime: 'Стаж курения',
      packYears: 'Пачко-лет',
      since: 'с',
      to: 'по',
      aDay: 'в день',
      alcoBeerPerWeek: {
        label: 'Пиво',
        popOverText: 'В среднем, сколько пива вы выпиваете в неделю?',
      },
      alcoWinePerWeek: {
        label: 'Вино',
        popOverText: 'В среднем, сколько вина вы выпиваете в неделю?',
      },
      alcoWhiskeyPerWeek: {
        label: 'Алкоголь',
        popOverText: 'В среднем, сколько алкогольных напитков вы выпиваете в неделю?',
      },
      coffePerDay: {
        label: 'Кофе',
        popOverText: 'В среднем, сколько чашек кофе вы выпиваете в день?',
      },
      teaPerDay: {
        label: 'Чай',
        popOverText: 'В среднем, сколько чашек чая вы выпиваете в день?',
      },
      exerciseWalkPerWeek: {
        label: 'Ходьба',
        popOverText: 'В среднем, сколько часов в неделю вы ходите пешком?',
      },
      exerciseRunPerWeek: {
        label: 'Бег',
        popOverText: 'В среднем, сколько часов в неделю вы бегаете?',
      },
      exerciseBicyclePerWeek: {
        label: 'Велосипед',
        popOverText: 'В среднем, сколько часов в неделю вы ездите на велосипеде?',
      },
      exerciseSimulatorsPerWeek: {
        label: 'Тренажеры',
        popOverText: 'В среднем, сколько часов в неделю вы используете тренажеры?',
      },
      exerciseSwimmingPerWeek: {
        label: 'Плавание',
        popOverText: 'В среднем, сколько часов в неделю вы плаваете?',
      },
      nutFruit: {
        label: 'Фрукты',
        popOverText: 'Как часто вы съедаете за день 2 фрукта или более?',
      },
      nutVegetables: {
        label: 'Овощи',
        popOverText: 'Как часто вы съедаете за день 1 порцию овощей или более?',
      },
      nutChicken: {
        label: 'Птица',
        popOverText: 'Как часто вы съедаете за неделю 150 граммов мяса птицы или более?',
      },
      nutRedMeat: {
        label: 'Красное мясо',
        popOverText: 'Как часто вы съедаете за неделю 150 граммов красного мяса или более?',
      },
      nutFish: {
        label: 'Рыба',
        popOverText: 'Как часто вы съедаете за неделю 150 граммов рыбы или более?',
      },
    },
    morbidity: {
      treated: 'Лечение',
      diagnosedDiabet: 'Диабет',
      diagnosedHypertension: 'Гипертензия',
      highBloodSugar: '$t(morbidity.diagnosedDiabet) $t(morbidity.treated)', // Значения подствляются автоматически
      highBloodPressure: '$t(morbidity.diagnosedHypertension) $t(morbidity.treated)', // Значения подствляются автоматически
      kidneyDesease: 'Заболевания почек',
      metabolicSyndrome: 'Нарушение обмена веществ',
      cardiovascular: 'Сердечно-сосудистые заболевания',
      stroke: 'Инсульт',
      cancer: 'Рак',
    },
    family: {
      parents: 'Родители'
    },
    metrics: {
      mmHg: 'мм рт. ст.',
      mmolL: 'мМоль/м',
      UL: 'ед./л',
      bloodPressureSystolic: 'Систолическое артериальное давление ($t(metrics.mmHg))',
      bloodPressureDiastolic: 'Диастолическое артериальное давление ($t(metrics.mmHg))',
      lipidTotalCholesterol: 'Общий холестерин ($t(metrics.mmolL))',
      highDensityLiprotein: 'ЛПВП ($t(metrics.mmolL))',
      lowDensityLiprotein: 'ЛПНП ($t(metrics.mmolL))',
      ratioOfTotalCholesterolToHdl: 'Соотношение общего холестерина и уровня ЛПВП',
      triglycerides: 'Триглицериды ($t(metrics.mmolL))',
      fastingBloodGlucose: 'Сахар в крови натощак ($t(metrics.mmolL))',
      glycatedHemoglobin: 'Гликированный гемоглобин (%)',
      cReactiveProtein: 'C-реактивный белок (мг/л)',
      alanineAminotransferase: 'Аланин-аминотрансфераза ($t(metrics.UL))',
      aspartateAminotransferase: 'Аспартат-аминотрансфераза ($t(metrics.UL))',
      gammaGlutamylTransferase: 'Гамма-глутамилтрансфераза ($t(metrics.UL))',
      glomerularFiltrationRate: 'Скорость клубочковой фильтрации (мл/мин/1.73 м^2)',
      albuminCreatinineUrineRatio: 'Соотношение альбумин/креатинин в моче (мг/ммоль)',
    },
    riskFactors: {
      normalRange: 'Нормальный диапазон',
      normalValue: 'Ожидаемое значение',
      expectedRange: 'Ожидаемый диапазон',
      actual: 'Реальное значение',
      obesity: 'Ожирение',
      movement: 'Подвижность',
      sleep: 'Сон',
      glucose: 'Глюкоза',
      lipids: 'Липиды  ',
      bloodPressure: 'Кровяное давление',
      ironMetabolism: 'Метаболизм железа',
    },
    risks: {
      timeHorizon: 'временной горизонт {{time}} лет',
      riskRatio: 'Риск',
      cardiovascular: 'Сердечно-сосудистые заболевания',
      metabolicDysfunction: 'Нарушение обмена веществ',
      liver: 'Заболевания печени',
      dementia: 'Деменция',
      atherosclerosis: 'Атеросклероз',
      COPD: 'Хроническая обструктивная болезнь легких',
      OSA: 'Синдром обструктивного апноэ во сне',
      ACM: 'Общая летальность',
      CHF: 'Хроническая сердечная недостаточность',
      aMI: 'Острый инфаркт миокарда',
      ESLD: 'Терминальная стадия заболевания печени',
      ESRD: 'Терминальная стадия заболевания почек',
      diabetesMellitus2: 'Сахарный диабет 2 типа',
      heartFailure: 'Сердечная недостаточность',
      totalCancer: 'Различные виды рака'
    },
    export: {
      printExport: 'Печать/Экспорт',
      report: 'отчет',
      patient: '$t(export.printExport) отчета пациента',
      physician: '$t(export.printExport) отчета врача',
    },
    drillDownGraph: {
      chart: {
        years: 'Лет',
        survival: 'Вероятность выживания',
        intervention: 'вмешательство',
        noIntervention: 'нет вмешательства',
      },
      filters: {
        data: 'Данные',
        intervention: 'Вмешательство',
        age: 'Возраст',
        gender: 'Пол',
        weight: '$t(basicData.weight) (кг)',
        nyhaClass: 'класс NYHA',
        ejectionFraction: 'Фракция выброса (%)',
        ischemicEtiology: 'Ишемическая этиология',
        sbp: 'SBP (мм рт. ст.)',
        sodium: 'Натрий (мэкв/л)',
        totalCholesterol: 'Общий холестерин (ммоль/л)',
        hemoglobin: 'Гемоглобин (г/дл)',
        lymphocytes: 'Лимфоциты (%)',
        uricACid: 'мочевая кислота (мг/дл)',
        diureticDose: 'Диуретическая доза (мг/день)',
        aceInhibitor: 'ИАПФ',
        bBlocker: 'β-блокаторы',
        arb: 'БРА',
        ksparingDiuretic: 'калийсберегающий диуретик',
        allopurinolUse: 'использование аллопуринола',
        statinUse: 'использование статина',
        biventricularPacemaker: 'бивентрикулярный кардиостимулятор',
        icd: 'имплантируемый кардиовертер-дефибриллятор',
        biventricularIcd: 'бивентрикулярный имплантируемый кардиовертер-дефибриллятор',
      }
    },
    plusRisks: {
      chart: {
        timeHorizon: 'Временной интервал: 5 лет',
        averageValue: 'Средний уровень',
        baseLineValue: 'Исходный уровень',
        confidenceInterval: 'Доверительный интервал',
        reset: 'Сбросить'
      },
      filter: {
        quitSmoking: 'Бросаю курить'
      }
    },
    month: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
    },
    report: {
      underweight: 'недовес',
      normal: 'норма',
      overweight: 'лишний вес',
      obese: 'ожирение',
      harmless: 'умеренное',
      harmlessCigarette: 'отсутствует',
      slightlyHarmful: 'выше нормы',
      extremelyHarmful: 'злоупотребление',
      requiresAction: 'присутствует',
      insufficient: 'недостаточный',
      sufficient: 'достаточный',
      good: 'выше нормы',
      healthy: 'норма',
      notQuiteHealthy: 'есть потенциал к улучшению',
      unhealthy: 'вредные',
      stronglyIncreased: 'крайне высокий',
      slightlyIncreased: 'повышенный',
      low: 'низкий',
      increased: 'отличается от нормы',
      lipidStronglyIncreased: 'сильно отличается от нормы',
      lipidSlightlyIncreased: 'отличается от нормы',
      lipidLow: 'норма',
    },
    overallRisk: {
      good: 'хорошее',
      fair: 'имеющее потенциал к улучшению',
      poor: 'серьезно ослабленное'
    },
    validator: {
      presence: 'Обязательное поле',
      tooLong: 'Слишком длинное (максимум {count, number} {count, plural, one {символ} other {символов}})',
      wrongLength: 'Должно быть {count, number} {count, plural, one {символ} other {символов}}',
      invalid: 'Не верный формат',
      notANumber: 'Допустимы только цифры',
      notAnInteger: 'Не целое число',
      greaterThan: 'Должно быть больше {count, number}',
      lessThanOrEqualTo: 'Должно быть меньше или равно {count, number}',
      greaterThanOrEqualTo: 'Должно быть больше или равно {count, number}',
      lessThan: 'Должно быть меньше {count, number}'
    },
    combinedPhaseResult: {
      id: 'Номер Вашей анкеты',
      info: 'Запишите его, если хотите  поделиться сведениями с Вашим лечащим врачом.',
      report: 'Получить отчёт'
    },
    phase1Report: {
      pdfBtn: 'PDF',
      header: 'Фаза 1',
      needMedicineYes1: 'Возможно, у вас есть проблемы со здоровьем, наличие которых необходимо проверить. Приведенные ниже рекомендации помогут Вам вести более здоровый образ жизни. Прежде всего, мы рекомендуем Вам обратиться к Вашему врачу или посетить одну из наших лабораторий для более детального анализа состояния Вашего здоровья. Для получения полной картины крайне важно сделать ряд анализов крови.',
      needMedicineYes2: 'Чтобы записаться на прием, воспользуйтесь этой ссылкой: [LINK TO REFERENCE SITE]',
      needMedicineNo1: 'Отличный результат! Существенных факторов риска для Вашего здоровья не обнаружено. Продолжайте вести здоровый образ жизни и повторите проверку Вашего здоровья через год. Приведенные ниже рекомендации помогут Вам сделать Ваши будни еще более здоровыми. Однако даже при столь хороших результатах, возможно, Вам стоит провести более углубленное обследование и мы приглашаем Вас посетить одну из наших лабораторий для сдачи анализа крови.',
      needMedicineNo2: 'Чтобы записаться на прием, пожалуйста, воспользуйтесь этой ссылкой: [LINK TO REFERENCE SITE]',
      generalRecommendationTitle: 'Общие рекомендации по здоровому образу жизни',
      generalRecommendationSubTitle: 'Наши рекомендации основаны на 4 основных элементах:',
      point1Title: 'Отказ от курения',
      point1Body: 'Курение, несомненно, является ключевым фактором риска и тут все в Ваших руках.  Курильщики живут в среднем, в зависимости от характера исследования, группы населения и т.д., от 5 до 11 лет меньше чем некурящие. Курение является основным фактором риска развития хронической обструктивной болезни легких (ХОБЛ) и основой для развития рака легких. Новейшие исследования показывают, что даже выкуривание всего одной сигареты в день не исключает высокую вероятность развития вышеуказанных факторов риска. Необходимо полностью отказаться от курения. Целый ряд организаций и консультантов окажут Вам всестороннюю поддержку в данном вопросе.',
      point2Title: 'Регулярные физические нагрузки',
      point2Body: 'Учитывая адекватные возможности Вашего организма, Вам следует больше двигаться и повышать физическую активность. Всего 75 минут физической активности высокой интенсивности в неделю, разделенные на 4 - 6 тренировки, значительно снижают риск развития сердечно-сосудистых заболеваний. В качестве альтернативы Вам будет достаточно и 150 минут физической активности умеренной интенсивности в неделю. Вы также можете сочетать физическую активность средней и высокой интенсивности. Мы рекомендуем Вам включить в Ваш план тренировок 2 силовые тренировки в неделю, направленные на развитие всех групп мышц, а также шестидневную сбалансированную программу, направленную на тренировку силы, выносливости, координации/баланса и подвижности.',
      point3Title: 'Сбалансированное питание',
      point3Body: 'Существует бесчисленное множество теорий о том, как питаться правильно. Мы рекомендуем Вам обратить внимание на так называемую средиземноморскую диету, эффективность которой была оценена и научно доказана. Питание в рамках средиземноморской диеты предполагает употребление растительных масел высокого качества, большого количества свежих овощей, фруктов, рыбы и морепродуктов. Допускается употребление (красного) сухого вина, не более одного бокала в день. Употребляйте пищу в компании, не торопясь и наслаждайтесь паузой в повседневной суете. Избегайте сахара, полуфабрикатов и сильно обработанных продуктов, например, из белой муки. Пейте достаточно воды, особенно в жаркую погоду или во время занятий спортом.',
      point4Title: 'Здоровый сон',
      point4Body: 'В последние годы возросло понимание важности сна для здоровья в целом и в особенности для сбалансированной работы человеческого мозга. В фазе быстрого сна (REM-фаза, когда появляются сновидения и начинается быстрое движение глаз) происходит консолидация содержимого памяти, а в глубоком сне мозг освобождается от "мусора", накопившегося за день. Основы улучшения гигиены сна включают в себя: сон в прохладной, темной комнате, изолированной от источников шума, отказ от кофеиносодержащих напитков после 16:00, отказ от стимулирующей деятельности в особенности после 21:00, никаких электронных устройств (для проверки электроннойпочты, текстовых сообщений и т.д) за час до сна. Кроме того, не ложитесь спать с полным желудком и помните, спальня - не место для решения рабочих вопросов и даже банальный просмотр телевизора в кровати может нарушить Ваше спокойствие. Наконец, кровать должна быть удобной, а подушка должна обеспечивать расслабленное положение головы и шеи.',
    },
    phase2Report: {
      header: 'Ваши результаты',
      headerText1: 'Большое спасибо за то, что Вы решили воспользоваться нашей онлайн услугой комплексного диагностического обследования, чтобы узнать больше о рисках для Вашего здоровья. Указанные ниже анализы и заключения основаны на Ваших данных о состоянии здоровья и измерениях, а также на результатах лабораторных исследований. В основу оценки легли опыт и знания более 5000 специалистов в области медицины, а также результаты клинических исследований в объеме более 400 миллионов человеко-лет.',
      headerText2: 'На заключительном этапе мы сообщим Вам результаты Вашего исследования и поделимся своими рекомендациями о том, как Вы можете улучшить состояние Вашего здоровья.',
      riskFactors: 'Факторы риска',
      overweightTitle: 'Избыточный вес и ожирение',
      overweightText1: 'Избыточный вес и ожирение являются одним из важнейших факторов риска развития ряда заболеваний, в том числе кардиоваскулярные заболевания, дисфункция печени и диабет. Кроме того, повышенная масса тела является фактором риска развития заболеваний суставов и обуславливает боли в спине. Также следует избегать недостаточного веса, поскольку при тяжелых заболеваниях резервов часто не хватает.',
      overweightText2: 'При индексе массы тела {{bmi}} Ваш вес по отношению к Вашему росту можно охарактеризовать как:',
      alcoholTitle: 'Чрезмерное употребление алкоголя',
      alcoholText1: 'Чрезмерное потребление алкогольных напитков приводит к появлению проблем со здоровьем даже у здоровых людей. Алкоголь в первую очередь негативно влияет на печень. Употребление алкоголя в вечернее время суток ведет к нарушению сна. Кроме того, спиртное – одна из наиболее распространённых причин несчастных случаев.',
      alcoholText2: 'Согласно Вашей оценки, Ваше потребление алкоголя можно охарактеризовать как:',
      smokingTitle: 'Курение',
      smokingText1: 'Курение и особенно вдыхание сигаретного дыма, является самым важным фактором риска для жизни, но все в Ваших руках. Курильщики живут в среднем от 5 до 11 лет меньше чем некурящие. Курение является основным фактором риска развития хронической обструктивной болезни легких (ХОБЛ) и основой для развития рака легких. Выкуривание всего одной сигареты в день не исключает высокую вероятность развития вышеуказанных заболеваний. Необходимо полностью отказаться от курения.',
      smokingText2: 'Необходимость действий с Вашей стороны:',
      movementTitle: 'Сидячий образ жизни',
      movementText1: 'Удобства современной жизни привели к недостатку физической активности. Длительное сидение повышает риск развития ряда заболеваний, среди которых можно выделить сердечно-сосудистые заболеваний, рак и снижение умственных способностей. Мы рекомендуем Вам либо 75 минут физической активности высокой интенсивности, либо 150 минут физической активности умеренной интенсивности в неделю. Вы также можете сочетать физическую активность средней и высокой интенсивности, включив от 4 до 6 тренировок в неделю. Силовая тренировка два раза в неделю идеально дополнит Вашу фитнес-программу.',
      movementText2: 'В соответствии с полученной от Вас информацией Ваш уровень физической активности можно охарактеризовать как:',
      nutritionTitle: 'Вредные привычки питания',
      nutritionText1: 'Вот уже несколько лет подряд в современном обществе наблюдается тренд к потреблению большого количества высококалорийных продуктов и сопутствующих вредных для здоровья веществ, как поваренная соль, сахар и консерванты. Здоровая диета основана, прежде всего, на потреблении свежих продуктов питания. За сутки необходимо съедать две порции фруктов и три порции овощей. Также необходимо выбирать полезные источники белка и углеводов из цельнозерновых продуктов. В целом следует избегать сахара, готовых продуктов с высокой степенью переработки и, прежде всего, переработанных мясных продуктов.',
      nutritionText2: 'В соответствии с полученной от Вас информацией Ваши привычки питания можно охарактеризовать как:',
      sleepTitle: 'Депривация сна',
      sleepText1: 'В последние годы возросло понимание важности сна для здоровья человека. Сон важен не только для физического восстановления, но и для закрепления содержимого памяти. Во время сна мозг очищается от вредных токсинов, накопившихся в нем в течение дня, что противодействует снижению умственных способностей. За редкими исключениями норма здорового сна для взрослого составляет 7 - 8 ½ часов. Независимо от того, являетесь ли вы жаворонком или совой, биоритмы каждого человека индивидуальны. Определить свою норму сна можно во время отпуска, банально высыпаясь и отходя ко сну без каких-либо внешних часов.',
      sleepText2: 'В соответствии с полученной от Вас информацией Ваш режим сна можно охарактеризовать как:',
      bloodPressureTitle: 'Артериальная гипертензия',
      bloodPressureText1: 'Высокое кровяное давление также является одним из наиболее распространенных заболеваний и, как при диабете, его развитие может также носить постепенный характер и может быть связано с генетической предрасположенностью. Высокое артериальное давление является фактором риска развития сердечно-сосудистых заболеваний и инсульта. Изменение Вашего образа жизни в сторону сбалансированного питания и большей двигательной активности может быть достаточным фактором для долговременной нормализации артериального давления.',
      bloodPressureText2: 'В соответствии с полученной от Вас информацией Ваш фактор риска:',
      glucoseTitle: 'Сахарный диабет',
      glucoseText1: 'Одной из важнейших задач системы регуляции обмена углеводов является поддержание концентрации глюкозы в крови на определенном уровне. Сбалансированная гормональная регуляция обеспечивает нормальный уровень сахара, в значительной степени независимый от приема пищи. При диабете 2-го типа из-за генетической предрасположенности и, главным образом, в контексте увеличения веса, происходит нарушение метаболизма сахара и, в конечном итоге, повреждение кровеносных сосудов и важных органов.',
      generalText2: 'В соответствии с полученной от Вас информацией Ваш фактор риска:',
      lipidsTitle: 'Нарушение жирового обмена',
      lipidsText1: 'Как и в случае регуляции обмена углеводов, генетический профиль существенно определяет уровень содержания холестерина и триглицеридов в крови. Оба типа жиров крови являются важными строительными элементами для организма, но если их показатели в крови слишком высоки, они вредны, особенно для кровеносных сосудов. Ухудшение состояния кровеносных сосудов, в свою очередь, является причиной, например, сердечных приступов, инсультов и других нарушений кровообращения.',
      ironMetabolismTitle: 'Нарушение метаболизма железа',
      ironMetabolismText1: 'Железо играет центральную роль во многих функциях организма, но, прежде всего, это важный компонент эритроцитов, разносящих кислород через кровь по всему организму. Поскольку абсорбция железа в кишечнике ограничена, поэтапно может развиться дефицит железа, при котором сначала потребности в железе превышают его потребление, вызывая прогрессирующее истощение запасов железа в костном мозге. Когда уменьшаются запасы железа, компенсаторно повышается абсорбция железа с пищей. Переизбыток железа, ровно как и его дефицит, крайне вреден для организма, поэтому важно своевременно обнаруживать нарушения метаболизма железа.',
      diseaseRisks: 'Риски развития заболеваний',
      diabetesMellitus2Title: 'Диабет',
      diabetesMellitus2Text1: 'Диабет 2-го типа является одним из наиболее распространенных заболеваний. Причины его развития вызваны генетической предрасположенностью. Заболевание развивается постепенно и как правило охарактеризовано набором веса. У диабетиков значительно высок риск развития сердечно-сосудистых заболеваний. Первыми мерами по борьбе с болезнью являются регулярные физические нагрузки и диета с уменьшенным содержанием калорий и углеводов с целью снижения веса.',
      diagnosedHypertensionTitle: 'Артериальная гипертензия',
      diagnosedHypertensionText1: 'Высокое кровяное давление также является одним из наиболее распространенных заболеваний и, как при диабете, его развитие может носить постепенный характер и может быть связано с генетической предрасположенностью. Хроническое высокое артериальное давление является фактором риска развития сердечно-сосудистых заболеваний и инсульта. Изменение Вашего образа жизни в сторону сбалансированного питания и большей двигательной активности может быть достаточным фактором для долговременной нормализации артериального давления.',
      kidneyDeseaseTitle: 'Почечная недостаточность',
      kidneyDeseaseText1: 'Почки играют центральную роль в регулировании водно-солевого баланса и позволяют организму выводить продукты обмена, распада, метаболизма, а также чужеродные химические соединения. Хроническое заболевание почек на ранней стадии развития не имеет признаков или симптомов, поэтому периодически необходимо проверять состояние почек, чтобы как можно скорее выявить болезнь и получить эффективное лечение. При этом основное внимание уделяется защите от дальнейшего ухудшения состояния за счет оптимизации факторов риска и приема лекарств.',
      liverTitle: 'Нарушение функций печени',
      liverText1: 'Печень является нашим центральным метаболическим органом, и ее часто называют химической фабрикой организма. Помимо ряда вирусов и других патогенов, поражающих печень, вред ей в первую очередь причиняют токсические вещества и регулярное чрезмерное потребление алкоголя. Как и при почечной недостаточности , тут крайне важно ранее обнаружение повреждений органа и устранение всех факторов вредного влияния на печень.',
      metabolicDysfunctionTitle: 'Нарушения жирового обмена',
      metabolicDysfunctionText1: 'Как и в случае с диабетом, генетический профиль существенно определяет уровень холестерина и триглицеридов в крови. Повышенный уровень холестерина, ровно как и повышенный уровень триглицеридов играет центральную роль в образовании так называемых «бляшек» в кровеносных сосудах. Это, в свою очередь, является причиной окклюзии сосудов, что приводит к сердечным приступам и инсультам. Повреждение сосудов развивается в течение многих лет, и при раннем выявлении и соответствующих мерах можно благоприятно повлиять на течение болезни и даже частично обратить его вспять.',
      heartFailureTitle: 'Сердечная недостаточность',
      heartFailureText1: 'Существует целый ряд факторов, которые вызывают повреждение миокарда, в числе которых и генетическая предрасположенность. Нарушение насосной функции сердца неизбежно ведет к снижению работоспособности организма в целом, а также нарушению психологического комфорта и качества жизни. На некоторые факторы риска можно повлиять, приняв соответствующие меры, поэтому своевременное выявление и лечение заболевания крайне важны.',
      overallRisk: 'Общий риск',
      healthSliceTitle: 'Общее состояние здоровья',
      healthSliceText: 'Вкратце общее состояние Вашего здоровья можно охарактеризовать как по большей части',
      healthSliceHigh: 'Отличный результат! Существенных факторов риска для Вашего здоровья не обнаружено. Продолжайте вести здоровый образ жизни и повторите проверку Вашего здоровья через год.',
      healthSliceMid: 'Несмотря на то, что Вы заботитесь о Вашем здоровье, мы выявили факторы риска. Мы рекомендуем следовать нашим советам по устранению выявленных рисков и обсудить результаты с Вашим лечащим врачом.',
      healthSliceLow: 'Мы выявили значительные риски для Вашего здоровья и советуем Вам обсудить сложившуюся ситуацию с Вашим лечащим врачом, который поможет Вам определить следующие шаги, необходимые для постепенного снижения рисков для скорейшего возвращения к более здоровой жизни.',
    }
  }
};

export default ru;
